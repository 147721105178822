.container {
  /* display: flex; */
  padding-top: 65px;
}

.tab-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .MuiSvgIcon-root{
  color: #FFE600 !important
} */

.MuiBottomNavigationAction-root:not(.Mui-selected) .MuiSvgIcon-root {
  color: #636363 !important;
}

.Mui-selected .MuiSvgIcon-root {
  color: #FFE600 !important;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: #FFE600!important;
  color: #000 !important;
}

.MuiTabs-indicator {
  background-color: #FFE600 !important;
  height: 3px !important;
}

/*.MuiSvgIcon-root,*/
.MuiBottomNavigationAction-label {
  color: #000000 !important
}



.header-title-container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.MuiInputBase-root {
  width: 100%;
}

.list-container {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.list-detail-container {
  flex-direction: column;
  display: flex;
}

.text-muted {
  font-size: 0.8em;
  color: #5e5e5e;
  display: flex;
  align-items: center;
}

.text-muted svg {
  color: #5e5e5e !important;
  margin-right: .5rem;
}

.d-flex {
  display: flex;
}

.primary {
  color: #FFE600;
}

.uppercase {
  text-transform: uppercase;
}

.col {
  flex-direction: column !important;
  display: flex;
}

.alert-absolute {
  position: absolute;
  bottom: 8vh;
  left: 50%;
  /* left: 5vw;
  right: 5vw; */
  z-index: 5000;
  transform: translateX(-50%);
}

.container-reward {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

@media only screen and (max-width: 600px) {
  .alert-absolute {
    position: absolute;
    bottom: 8vh;
    left: 5vw;
    right: 5vw;
    z-index: 5000;
    transform: none;
  }

  .container {
    padding-top: 58px;
  }

  .container-reward {
    padding-top: 0;
  }
  
  .box-content-trophy span {
    top: 0.45rem!important;
    font-size: 12px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.uppercase input {
  text-transform: uppercase;
}

.input-error {
  border-bottom: solid 1px red;
  border-left: solid 1px red;
  border-right: solid 1px red;
  color: red !important;
}

.input-error input {
  color: red !important;
}


.input-success {
  border-bottom: solid 1px green;
  border-left: solid 1px green;
  border-right: solid 1px green;
  color: green !important;
}

.input-success input {
  color: green !important;
}

progress {
  margin: 4px 10px;
}


.lds-ring {
  display: inline-block;
  position: relative;
  min-width: 10px;
  width: auto;
  min-height: 10px;
  height: auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  margin: 8px;
  border: 8px solid #1a76d2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1a76d2 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.radioAddress span {
  font-size: 1.3em;
}

.warranty-list-container {
  padding-bottom: 5em;
}

.hidden {
  display: none !important;
}

.input-icon-text {
  position: absolute;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.battery-list-item {
  border-bottom: solid 1px #dfdfdf !important;
  background: #fbfbfb !important;
}

.cardName-title {
  font-size: 1.2em !important;
}

.container-client .MuiPaper-root {
  padding: .2rem 1.5rem;
  border: 1px solid #000000;
  border-radius: 12px;
  background-color: #ffffff !important;
}

.modal-custom-bmg .MuiDialog-paper {
  border-radius: 20px !important;
  background-color: #fff !important;
  background: #fff!important;
}

.modal-banner-custom {
  background-color:transparent !important;
}

.modal-banner-custom .MuiDialog-paper{
  border-radius: 28px !important;
  background-color:transparent !important;
  box-shadow: none!important;
  max-height: calc(100% - 40px);
}

.modal-banner-custom .MuiSvgIcon-root{
  font-size: 1rem;
}

.green-color-bmg {
  color: #4BD200 !important;
}

.modal-banner-custom .MuiDialogContent-root::-webkit-scrollbar {
  width: 0px;
}

.button-icon-register div {
  display: inherit;
}

/* .modal-banner-custom .MuiDialogContent-root::-webkit-scrollbar-track {
  background: transparent;
}

.modal-banner-custom .MuiDialogContent-root::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 20px;
  border: 3px solid #fff;
} */