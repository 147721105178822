.inp-login > label{
  color: #fff
}
.inp-login > div input {
  color: #fff !important;
  text-transform: uppercase;
}

.inp-login > ::before {
  border-bottom: solid 1px #fff !important;
}