@font-face {
  font-family: 'font-primary';
  src: url('./assets/fonts/D-DIN-PRO-500-Medium.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'font-primary', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0 !important;
  /* background-image: url('./assets/images/home-bg.svg'); */
  background: url('./assets/images/home-bg.svg') no-repeat center center fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: 'font-primary', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}


button,
span,
label,
input,
h1,
h2,
h3,
h4,
h5,
h6,
table {
  font-family: 'font-primary', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.MuiPaper-root:not(header):not(.card-product) {
  background-color: #F0F0F0 !important;
  background: url('./assets/images/home-bg.svg') no-repeat center center fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.MuiList-root {
  background-color: transparent !important;
}

.MuiDivider-root {
  border-color: #000000 !important;
}

header+div .MuiInputBase-root input {
  /* background-color: #FFFFFF!important;
  border-radius: 10px!important; */
}

header .MuiPaper-root h6 {
  background-color: #ffffff;
}

.container-modal-warranty-request form {
  background-color: #FFFFFF !important;
}

.section-box-title {
  position: relative;
  border-bottom: 2px solid #000 !important;
  margin-top: 1.5rem;
  text-align: center;
}

.section-box-title::after {
  content: "";
  position: absolute;
  bottom: -4px;
  right: 0;
  margin: 0 auto;
  left: 0;
  width: 60%;
  height: 7px;
  background-color: #FFE700;
  z-index: 1;
}

.section-box-title.toggle-title div:first-child {
  justify-content: center;
  margin-bottom: 0;
}

.container-accordion {
  box-shadow: none !important
}

.container-accordion::before {
  opacity: 0 !important;
}

.card-product {
  background: #fff;
  width: 100%;
  box-shadow: none !important;
}

.container-reward .card-product {
  border-radius: 15px;
}

.container-reward-cart .card-product.small {
  border-radius: 8px;
  img {
    object-fit: cover;
  }
}

.card-product.small {
  width: 90px;
  text-align: center;
}

/* .card-product:first-child {
  margin-left: 1rem;
}

.card-product:last-child {
  margin-right: 1rem;
} */

.card-product img.card-image {
  max-height: 191px;
  object-fit: contain;
}

.card-product .card-title {
  padding: 0 !important;
  background-color: #FFE700;
}

.card-product .price {
  font-weight: bold;
  font-size: 0.6rem;
}

.card-product .product-name {
  font-size: 0.75rem;
  margin: 0;
  font-weight: bold;
  padding: 0.5rem;
  line-height: normal;
  overflow: hidden;
  text-wrap: nowrap;
  width: 86%;
  /* text-overflow: ellipsis; */
}

.card-product.small .product-name {
  font-size: 0.7rem;
  padding: 0.3rem;
  width: 93%;
}

.box-content-trophy {
  position: relative;
}

.box-content-trophy img {
  max-width: 100%;
}

.box-content-trophy span {
  position: absolute;
  top: 0.65rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-weight: bold;
}

.nivel-disabled {
  filter: grayscale(1);
}

.content-icon-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 55px;
  cursor: pointer;
}

.label-quantity-cart {
  position: absolute;
  top: 0;
  right: 0;
  left: 1rem;
  margin: 0 auto;
  background: #ffe600;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  z-index: -1;
  font-weight: bold;
  padding: 0.2rem 0.2rem 0 0.5rem;
}

.border-level-right {
  border-right: 2px solid;
  border-color: #B1B1B1 !important;
}

.container-items-level>div:not(:last-child) {
  border-right: 2px solid;
  border-color: #B1B1B1 !important;
}

.level-title-small {
  font-weight: bold !important;
  text-align: center;
  font-size: 0.6rem !important;
  color: #6A7276;
  line-height: normal !important;
}

.content-label-price {
  position: absolute;
  bottom: 1.9rem;
  right: 0.4rem;
  display: grid;
  place-items: center;
}

.content-label-price img.icon {
  width: 1.3rem;
  height: 1.3rem;
}

.content-actions-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 20px;
  width: 7rem;
  margin-top: 1rem;
}

.quantity-cart {
  margin: 0!important; 
  font-weight: bold!important;
  font-size: 1rem!important; 
}

@media only screen and (max-width: 600px) {

  .card-product img.card-image {
    max-height: 130px;
  }

  .card-product .price {
    font-weight: bold;
    font-size: 0.5rem;
  }

  .content-label-price img.icon {
    width: 1rem;
    height: 1rem;
  }

  .container-reward .title-client {
    font-size: 1.15rem;
    padding-left: 0.5rem;
  }

  .box-bg-title {
    background-size: 80%!important;
  }

  .box-bg-title h6 {
    font-size: 1rem;
  }

  .section-box-title h5 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .section-box-title::after {
    width: 40%;
  }

  .content-actions-cart {
    width: max-content;
  }

  .quantity-cart {
    font-size: 0.9rem!important; 
  }
}