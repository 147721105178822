.scanner-container {
  display: block;
  margin-top: 2vh;
}

.input-container {
  display: flex;
  flex: 1;
  /* margin-bottom: 2vh; */

  padding: 10px;
  border-radius: 5px;
}

.container-bg {
  background-color: #F5FAFA;
}

.tab-one-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tab-one-container [role="tabpanel"] div.MuiBox-root:first-child {
  /* padding: 12px; */
}

.warranty-container {
  /* background: #e3e3e3; */
  display: flex;
  flex: 1;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  padding-top: 20px;
}



.new-battery-container {
  /* padding: 4vh 2vw; */
  padding: 1rem 2vw;
  max-height: 93vh;
  /* overflow: auto; */
  padding-bottom: 5em;
}

form.new-battery-container {
  overflow: hidden;
  overflow-y: auto;
}


.code-battery-container {
  background: #f1f1f1;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
}

.new-battery-container>.battery-container {
  background: #F5FAFA;
  /*#efefef;*/
  /* margin: 0 0 20px 0; */
  padding-bottom: 10px;
  border-radius: 5px;
  padding: 1rem;
}

.battery-container-info {
  background: #F5FAFA;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 1rem;
}

.battery-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battery-detail-container .title {
  color: black;
  /* font-weight: bold; */

}

.battery-detail-name {
  /* position: absolute; */
  margin-top: -2.5rem;
  background: #F5FAFA;
  width: 30%;
  margin-left: 1.5rem;
}

.battery-container .content-battery-code {
  border: 1px solid;
  border-radius: 12px;
  padding-top: 1.5rem;
}

.battery-detail-container .subtitle {
  color: #787878;
  font-size: 0.7em;
  font-weight: bold;
}

.new-battery-container>.details-container .details {
  margin: 2vh 0;
  padding: 1vh 2vw;
  border: solid 2px;
  border-radius: 7px;
  /* box-shadow: 0px 0px 5px 1px #c7c7c7; */
  box-shadow: 0px 0px 5px 1px #FFE600;
  /* background-color: #F5FAFA; */
}

.new-battery-container>.details-container .details.success {
  box-shadow: 0px 0px 5px 1px #41a927;
}

.new-battery-container>.details-container .details summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-battery-container>.details-container .details .details-body {
  margin-top: 13px;
}

.new-battery-container>.details-container .active {
  border-color: #FFE600;
  /*#ffc608;*/
}

.new-battery-container>.mainteniance-container {}

.new-battery-container>.button-container {
  /* position: sticky; */
  z-index: 8;
  /* bottom: 11vh; */
  bottom: 1rem;
  /* width: 92vw;  */
  padding-top: 1rem;
}

.input-battery {
  margin: 5px 2px !important;
  border-radius: 5px;
}

.input-battery>div {
  border-radius: 5px;
  border: none !important;
}

.input-battery>div input {
  border-radius: 5px;
  border: none !important;
}

.MuiFilledInput-input::before {
  border: none !important;
  border-bottom: 30px !important;
}


details>summary {
  list-style: none;
}

details>summary::-webkit-details-marker {
  display: none;
}

.input-maintenance label {
  font-size: 12px !important;
}


.warranty-list-container th {
  font-weight: bold !important;
}

.warranty-list-container th,
.warranty-list-container td {
  font-size: 0.6rem
}

span.w-title {
  color: #878787;
  font-size: 0.5rem;
}



.commets {
  /* background: #f5f5f5; */
  padding: 10px 5px;
}

.comment-area {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.button-send-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comments-list {
  padding: 5px 5px;
  max-height: 40vh;
  overflow: auto;
}

.comment-bubble {
  display: flex;
  flex-direction: column;
  border: solid 1px #b9b9b9;
  margin-bottom: 10px;
  padding: 10px 0px 10px 15px;
  background: #fff;
  border-radius: 10px;
  /* box-shadow: 0px 0px 3px #bbb; */
}


.comment-bubble .date {
  font-size: 0.7rem;
  color: #747474;
}

.comment-bubble .comment-text {
  margin-top: 5px;
}


.new-battery-container .state-container {
  display: flex;
  justify-content: flex-end;
}

.drawer-header {
  padding: 10px 6px;
  background: #000;
}

.drawer-header .logo {
  max-width: 100%;
  margin-top: 6px;
}


.drawer-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  background-color: #FFFFFF;
}

.client-container {
  margin: 10px 15px;
}


.warranty-list-container {
  padding-bottom: 3em;
}

.container-table-list {
  border-radius: 15px !important;
}

.container-table-list tbody {
  background-color: #fff;
}

.container-table-list thead th {
  background-color: #DADADA;
}

.img-input-container {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.input-text-container {
  display: flex;
  position: relative;
  align-items: center;

}


.input-icon-text {
  position: absolute;
  right: 10px;
  /* top: 0; */
}


.hidden {
  display: none !important;
}

.input-icon-text {
  position: absolute !important;
}

.select-input-update .input-icon-text {
  position: absolute !important;
  right: 25px !important;
}



.btn-success {
  background-color: #41a927 !important;
}

.success {
  color: #41a927 !important;
}

.new-battery-container>.details-container .success {
  border-color: #41a927 !important;
}

.new-battery-container>.details-container .disabledcard {
  border-color: #7f7f7f !important;
}

.new-battery-container>.details-container .details.disabledcard {
  box-shadow: 0px 0px 5px 1px #7f7f7f;
}

.new-battery-container .MuiPaper-root,
.new-battery-container .code-battery-container .MuiFormControl-root,
.new-battery-container .code-battery-container+form .MuiFormControl-root,
.form-maintenance .MuiFormControl-root,
.new-battery-container-solicitud form .MuiFormControl-root,
.new-battery-container-detail .MuiFormControl-root {
  background-color: #fff !important;
}

.form-maintenance .details {
  background-color: #fff;
}

.img-show-container {
  justify-content: center;
  display: flex;
  margin: 10px 0;
  background: #f1f1f1;
  padding: 20px 0px;
}

img#showImg {
  max-width: 40%;
}


.new-warranty-from {
  margin: 10px 0;
}


img.icon.icon-new-warranti {
  max-width: 2vw;
}


@media only screen and (max-width: 600px) {
  img.icon.icon-new-warranti {
    max-width: 5vw;
  }

  img#showImg {
    width: 80%;
  }

  .label-state {
    font-size: 0.65em !important
  }
}

@media only screen and (min-width: 1200px) {
  h3.w-title {
    color: #000;
    font-size: 1.3rem;
  }

  span.w-title {
    color: #878787;
    font-size: 1.6em;
  }

  span.w-subtitle {
    font-size: 2em;
  }

  .w-number {
    font-size: 1.8em;
  }

}

@media only screen and (min-width: 1700px) {
  img.icon.icon-new-warranti {
    max-width: 1vw;
  }



}

label {
  z-index: 0 !important;
}


.label-state {
  font-size: 0.9em
}




.battery-warranty-list-container {
  /* background: #fff; */
  border-radius: 3px;
  /* box-shadow: 1px 1px 7px 0px #8e8e8e; */
  margin: 15px 0;
}

.battery-warranty-list-container table {
  background: #fff;
}


.date-container {
  margin-top: 1em;
  padding: 0 10px;
  margin-bottom: 1em;
}


details.details {
  margin: 2vh 0;
  padding: 1vh 2vw;
  border: solid 1px #c4c4c4;
  border-radius: 7px;
  box-shadow: 0px 0px 5px 1px #9393935c;
}

.details summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.hide-address {
  opacity: 0;
  z-index: -1;
}

.MuiBox-root {
  border-color: #000000 !important;
}

.form-input-custom {
  border: 1px solid #000000 !important;
  border-radius: 12px !important;
  background-color: transparent !important;
}

.black-text-color {
  color: #000000 !important;
}

.icon.icon-custom {
  width: 1em;
  height: 1em;
}

.icon.icon-custom-tab {
  width: 2.5em;
  height: 2.5em;
}

.MuiMenuItem-root .MuiSvgIcon-root {
  color: #FFE600 !important;
}

.drawer-footer .MuiSvgIcon-root {
  color: #000000 !important;
}

header+div.search-clients .icon-search-bmg {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  right: unset;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

header+div .icon-search-bmg+div input {
  margin: 0 !important;
}

#frm-battery .dropdown-code legend>span {
  padding-left: 50px;
}

.btn-bmg-update-version,
.mt-2 {
  margin-top: 1rem !important;
}

.bmg-custom-tab {
  background-color: #FFFFFF !important;
  border-width: 2px !important;
  /* border-color: red!important; */
  border-radius: 20px;

}

.bmg-custom-tab:first-child {
  /* border-top-left-radius: 15px;
  border-bottom-left-radius: 15px; */
  border-radius: 20px;
}

.bmg-custom-tab:last-child {
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  /* border: 1px solid red!important; */
  border-radius: 20px;
}

.battery-warranty-list-container .bmg-container-tabs .MuiTabs-indicator {
  height: 0 !important;
}

.battery-warranty-list-container .bmg-container-tabs .Mui-selected {
  background-color: #FFE600 !important;
  border-radius: 20px;
  border: 2px solid #DADADA;
}

.battery-warranty-list-container .bmg-custom-tab {
  min-height: auto !important;
  padding: .5rem;
}

.bmg-container-tabs div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bmg-container-tabs div:first-child div:first-child {
  background-color: #FFFFFF !important;
  border-radius: 20px;
  border: 2px solid #DADADA;
}

.container-details-maintenance {
  position: relative;
}

.container-details-maintenance ul li {
  border: solid 2px;
  border-radius: 7px;
  margin: 2vh 0;
}

.container-details-maintenance::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #BDBDBD;
}

.container-body-action-corp {
  /* height: calc(55vh - 1rem); */
  /* overflow: hidden; */
  /* overflow-y: auto; */
}

.container-client button{
  /* position: sticky; 
  z-index: 8;*/
  bottom: 2rem;
}

.container-action-corp .tab-action-corp {
  border-radius: 100%;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
}

.container-action-corp .Mui-selected {
  background-color: #FFE600;
}

.container-action-corp .MuiTabs-indicator {
  height: 0 !important;
}

.container-body-action-corp .item-codigo:not(.item-codigo:last-child) {
  border-bottom: solid 1px;
}

.btn-address-sucursal-active {
  border: 1px solid #000!important;
  box-shadow: 0px 0px 5px 1px #FFE600!important;
  background: #F0F0F0!important;
  color: #000!important
}

.swiper-button-prev, .swiper-button-next {
  color: #000;
}